import axios from "@/web/services/axios";

export async function login(email, password) {
  const requestData = { email, password };
  return makeAuthRequest("post", "sign_in", requestData);
}

export async function loginViaFacebook(accessToken, gdprConsent) {
  const requestData = { token: accessToken };
  if (gdprConsent) {
    requestData.gdpr = true;
  }
  return makeAuthRequest("post", "sign_in_facebook", requestData);
}

export async function loginViaGoogle(googleToken, gdprConsent) {
  const requestData = { token: googleToken };
  if (gdprConsent) {
    requestData.gdpr = true;
  }
  return makeAuthRequest("post", "sign_in_google", requestData);
}

export async function loginViaApple(appleToken, gdprConsent, firstName, lastName) {
  const requestData = { token: appleToken, first_name: "Apple", last_name: "User" };
  if (gdprConsent) {
    requestData.gdpr = true;
    requestData.first_name = firstName;
    requestData.last_name = lastName;
  }
  return makeAuthRequest("post", "sign_in_apple", requestData);
}

export async function loginViaMagicLink(magicLinkToken) {
  const requestData = { token: magicLinkToken };
  return makeAuthRequest("post", "sign_in_magic_link", requestData);
}

export async function register(email, password, firstName, lastName, eventId, metaData) {
  let requestData = {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
  };

  let additionalParams = {};
  if (eventId) {
    additionalParams.event_id = eventId;
  }
  if (metaData) {
    additionalParams.specific_fields = metaData;
  }
  if (Object.keys(additionalParams).length > 0) {
    requestData = {
      ...requestData,
      additional_params: additionalParams,
    };
  }
  return makeAuthRequest("post", "sign_up", requestData);
}

export async function refresh(refreshToken) {
  const requestData = { refresh_token: refreshToken };
  return makeAuthRequest("put", "tokens", requestData);
}

export async function logout() {
  await axios.delete("sign_out");
  return true;
}

export async function reset(email) {
  await axios.post("user/recovery_account/generate_new_password", { email });
  return true;
}

export async function joinToEvent(eventId) {
  const res = await axios.post(`events/join`, { event_ids: [eventId] });
  const status = res && res.data && res.data[0] && res.data[0].status;
  return status === "added";
}

export async function requestJoinToEvent(eventId) {
  await axios.post(`events/${eventId}/user/request_join_to_event`);
  return true;
}

export async function deleteAccount() {
  await axios.delete("user");
  return true;
}

export async function changePassword(newPassword, newPasswordConfirmation) {
  const data = {
    password: newPassword,
    password_confirmation: newPasswordConfirmation,
  };
  await axios.put(`user/change_password`, data);
  return true;
}

export async function resetPasswordWithToken(token, newPassword, newPasswordConfirmation) {
  const params = { token: token, password: newPassword, password_confirmation: newPasswordConfirmation };
  await axios.post(`user/recovery_account/change_password`, params);
  return true;
}

export async function resendEmailConfirmation({ email, eventId }) {
  const params = {
    email: email,
    event_id: eventId,
  };
  await axios.post("user/resend_confirm", params);
  return true;
}

async function sendMagicLink(email, eventId) {
  const params = {
    email: email,
    event_id: eventId,
    source: "lp",
  };
  await axios.post("send_magic_link", params);
  return true;
}

async function makeAuthRequest(method, url, data) {
  const response = await axios.request({ method, url, data });
  return parseResponse(response);
}

function parseResponse(response) {
  const { data } = response;

  return {
    token: data.token,
    refreshToken: data.refresh_token,
    expirationDate: data.expiration_date,
  };
}

export default {
  login,
  loginViaFacebook,
  loginViaGoogle,
  loginViaApple,
  register,
  refresh,
  logout,
  reset,
  joinToEvent,
  requestJoinToEvent,
  deleteAccount,
  changePassword,
  resetPasswordWithToken,
  resendEmailConfirmation,
  sendMagicLink,
  loginViaMagicLink,
};
