import axios from "@/web/services/axios";
import paymentService, { checkVatNumberInVies } from "@/web/services/payment";
import stripeService from "@/web/services/stripe";
import Constants from "@/web/constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import PaymentConstants from "@/shared/constants/payment-constants";
import { getDiscountAmountForSingleTicket, getMarketingDiscountAmount, wait } from "@/shared/utils";

// Supported payment gateways
// eslint-disable-next-line prettier/prettier
const paymentGateways = {
  [PaymentConstants.GATEWAY_P24_ID]: "processP24Payment",
  [PaymentConstants.GATEWAY_STRIPE_ID]: "processStripePayment",
  [PaymentConstants.GATEWAY_PAYU_ID]: "processPayuPayment",
  [PaymentConstants.GATEWAY_BLUE_MEDIA_ID]: "processBlueMediaPayment",
  [PaymentConstants.GATEWAY_FREE_TICKET_ID]: "processFreePayment",
};

const supportedGateways = [
  PaymentConstants.GATEWAY_PAYU_ID,
  PaymentConstants.GATEWAY_P24_ID,
  PaymentConstants.GATEWAY_STRIPE_ID,
  PaymentConstants.GATEWAY_BLUE_MEDIA_ID,
];
const defaultGateways = [PaymentConstants.GATEWAY_PAYU_ID];

export const state = () => ({
  gateway: null,
  personalForm: null,
  guestsPersonalForms: null,
  additionalForm: null,
  guestsAdditionalForms: null,
  invoiceForm: null,
  consentForm: null,
  discount: null,
  transactionStatus: null,
  marketingAgreement: null,
});

export const mutations = {
  setGateway(state, gateway) {
    const gateways = Object.keys(paymentGateways);
    if (gateways.includes(gateway)) {
      state.gateway = gateway;
    } else {
      state.gateway = null;
    }
  },

  setPersonalForm(state, formData) {
    state.personalForm = { ...formData };
  },

  setGuestsPersonalForms(state, formData) {
    state.guestsPersonalForms = { ...formData };
  },

  setAdditionalForm(state, formData) {
    state.additionalForm = { ...formData };
  },

  setGuestsAdditionalForms(state, formData) {
    state.guestsAdditionalForms = { ...formData };
  },

  setInvoiceForm(state, formData) {
    state.invoiceForm = { ...formData };
  },

  setConsentForm(state, formData) {
    state.consentForm = { ...formData };
  },

  clearForms(state) {
    state.gateway = null;
    state.personalForm = null;
    state.additionalForm = null;
    state.invoiceForm = null;
    state.consentForm = null;
    state.guestsPersonalForms = null;
    state.guestsAdditionalForms = null;
  },

  setDiscount(state, discount) {
    state.discount = discount;
  },

  setTransactionStatus(state, status) {
    state.transactionStatus = status;
  },

  setMarketingAgreement(state, value) {
    state.marketingAgreement = value;
  },
};

export const getters = {
  stripePublishKey(state, getters, rootState) {
    return rootState.event.stripe_publish_key;
  },

  availableGateways(state, getters, rootState, rootGetters) {
    const paymentConfig = rootGetters.lpSettings[LpConfigConstants.ROOT_FIELDS.PAYMENT];
    if (
      paymentConfig &&
      paymentConfig[LpConfigConstants.PAYMENT_FIELDS.GATEWAYS] &&
      paymentConfig[LpConfigConstants.PAYMENT_FIELDS.GATEWAYS].length
    ) {
      const filteredGateways = paymentConfig[LpConfigConstants.PAYMENT_FIELDS.GATEWAYS].filter(gateway =>
        supportedGateways.includes(gateway)
      );
      if (filteredGateways.length) {
        return filteredGateways;
      }
    }
    return defaultGateways;
  },

  purchaseSummaryRows(state, getters, rootState, rootGetters) {
    if (state.guestsPersonalForms && rootGetters["eventTickets/getAllTicketsFromModule"].length) {
      const discount = state.discount;
      const allEventTickets = rootGetters["eventTickets/getAllTicketsFromEvent"];
      const { marketing_enabled: eventMarketingEnabled, marketing_discount_percentage: eventMarketingDiscountPercentage } = rootState.event;

      const marketingAgreement = state.marketingAgreement && eventMarketingEnabled;

      let usedDiscountsCount = 0;

      return Object.entries(state.guestsPersonalForms)
        .map(([key, value]) => [
          Number.parseInt(key),
          { forms: value, ticket: allEventTickets.find(ticket => ticket.id === Number.parseInt(key)) },
        ])
        .sort(([keyA, valueA], [keyB, valueB]) => valueA.ticket.price - valueB.ticket.price)
        .reduce((resultArr, [formTicketId, { forms, ticket }]) => {
          if (forms && forms.length) {
            const ticketsQuantity = forms.length;

            //Tickets info
            const row = {
              [PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.TICKET]: ticket,
              [PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.QUANTITY]: ticketsQuantity,
            };

            //Event addons
            let ticketAddons = ticket.event_addons;

            let addonsRow = ticketAddons
              .map(addon => {
                // Custom change for evolution event, discount code 100% for addons
                let forceZeroPrice = rootState.event.id === 1509 && discount != null && discount.discount_percentage === 100;
                return {
                  'force-zero-price': forceZeroPrice,
                  [PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_MODEL]: addon,
                  [PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_QUANTITY]: forms.reduce((acc, form) => {
                    return acc + (form.addons && addon.id in form.addons && form.addons[addon.id] ? 1 : 0);
                  }, 0),
                };
              })
              .filter(it => it[Constants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_QUANTITY] > 0);
            if (addonsRow.length) {
              row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_ROW] = addonsRow;
            }

            //Bulk discount
            let ticketBulkDiscounts = ticket.event_ticket_bulk_discounts.sort((a, b) => b.threshold - a.threshold);
            let foundBulkDiscount = ticketBulkDiscounts.find(bulkDiscount => bulkDiscount.threshold <= ticketsQuantity);
            if (foundBulkDiscount) {
              row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.BULK_DISCOUNT] = foundBulkDiscount;
            }

            //Discount code
            if (discount && ticket.price > 0) {
              const availableDiscounts = discount.max_tickets_quantity - usedDiscountsCount;
              if (availableDiscounts > 0 && discount.ticket_ids.includes(formTicketId)) {
                row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.DISCOUNT] = discount;
                row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.DISCOUNTED_TICKETS_QUANTITY] = Math.min(availableDiscounts, forms.length);
                usedDiscountsCount += forms.length;
              }
            }
            //Marketing discount
            if (marketingAgreement) {
              row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.MARKETING_DISCOUNT] = eventMarketingDiscountPercentage;
            }
            resultArr = [...resultArr, row];
          }
          return resultArr;
        }, [])
        .sort((a, b) => b.ticket.price - a.ticket.price);
    }
    return null;
  },

  finalPrice(state, getters) {
    if (getters.purchaseSummaryRows) {
      return getters.purchaseSummaryRows.reduce((acc, row) => {
        const ticketPrice = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.TICKET].price;
        const discountedTicketsQuantity = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.DISCOUNTED_TICKETS_QUANTITY] || 0;
        const ticketsQuantity = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.QUANTITY];
        const discount = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.DISCOUNT];
        const marketingDiscount = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.MARKETING_DISCOUNT];
        const bulkDiscount = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.BULK_DISCOUNT];
        const eventAddons = row[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_ROW];

        let currentRowPrice = ticketPrice * ticketsQuantity;

        //Bulk discount
        if (bulkDiscount) {
          currentRowPrice = currentRowPrice - bulkDiscount.discount_amount * ticketsQuantity;
        }

        //Discount codes
        currentRowPrice = currentRowPrice - getDiscountAmountForSingleTicket(discount, ticketPrice) * discountedTicketsQuantity;
        //Marketing discount
        currentRowPrice =
          currentRowPrice -
          getMarketingDiscountAmount(discount, marketingDiscount, ticketPrice, discountedTicketsQuantity, ticketsQuantity);

        let currentRowAddonsPrice = 0;
        if (eventAddons) {
          currentRowAddonsPrice = eventAddons.reduce((acc, addonRow) => {
            if (addonRow['force-zero-price'] === true) {
              return acc;
            }
            return (
              acc +
              addonRow[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_MODEL].price *
                addonRow[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_QUANTITY]
            );
          }, 0);
        }
        return acc + Math.max(0, currentRowPrice) + currentRowAddonsPrice;
      }, 0);
    } else {
      return null;
    }
  },

  gdprConsentRequired(state, getters) {
    return Object.values(state.guestsPersonalForms).reduce((acc, formsArray) => acc + formsArray.length, 0) > 1;
  },
};

export const actions = {
  async setMultiplePersonalForms({ dispatch, commit, rootState, rootGetters, state }, formData) {
    const currentUserEmail = rootGetters["currentUser/email"];
    let currentUserPersonalForm = state.personalForm;
    Object.values(formData).forEach(ticketForms => {
      ticketForms.forEach((personalForm, index, object) => {
        if (currentUserEmail && personalForm.email !== currentUserEmail && personalForm.thisIsMe) {
          personalForm.email = currentUserEmail;
          currentUserPersonalForm = personalForm;
        } else if (currentUserEmail && personalForm.email === currentUserEmail && !personalForm.thisIsMe) {
          personalForm.email = "";
        } else if (!currentUserEmail && personalForm.thisIsMe) {
          currentUserPersonalForm = personalForm;
        } else if (currentUserEmail && personalForm.email === currentUserEmail && personalForm.thisIsMe) {
          currentUserPersonalForm = personalForm;
        }
      });
    });
    if (currentUserPersonalForm) {
      commit("setPersonalForm", currentUserPersonalForm);
    }
    commit("setGuestsPersonalForms", formData);
  },

  async setMultipleAdditionalForms({ dispatch, commit, rootState, rootGetters, state }, formData) {
    let currentUserPersonalForm = state.personalForm;

    let currentUserAdditionalForm;
    Object.entries(formData).forEach(([key, value]) => {
      if (key === currentUserPersonalForm.email) {
        currentUserAdditionalForm = value.metaData;
      }
    });
    commit("setAdditionalForm", currentUserAdditionalForm);
    commit("setGuestsAdditionalForms", formData);
  },

  async pay({ dispatch, state, rootGetters }, { eventId, gdprConsent }) {
    const gateway = state.gateway;
    const actionName = paymentGateways[gateway];
    const discountCode = state.discount && state.discount.discount_code;
    const marketingAgreement = state.marketingAgreement;

    const personalForm = state.personalForm;
    const currentUserEmail = personalForm.email;

    const currentUserSpecificFields = { ...state.invoiceForm, ...state.additionalForm, ...state.consentForm };
    const guestsTickets = mapGuestsFormsToBodyRequest(currentUserEmail, state.guestsPersonalForms, state.guestsAdditionalForms);
    const personalFormMapped = mapPersonalFormToBodyRequest(personalForm, state.guestsPersonalForms);
    await dispatch(actionName, {
      eventId,
      ticketId: personalFormMapped.ticket_id,
      personalForm: personalFormMapped,
      discountCode,
      marketingAgreement,
      gdprConsent,
      guestsTickets,
      currentUserSpecificFields,
    });
  },

  async updateUserData({ dispatch, state }, eventId) {
    const { firstName, lastName } = state.personalForm;

    await dispatch("currentUser/updateUser", { first_name: firstName, last_name: lastName }, { root: true });
  },

  async [paymentGateways[PaymentConstants.GATEWAY_P24_ID]](
    { dispatch },
    { eventId, ticketId, personalForm, discountCode, marketingAgreement, gdprConsent, guestsTickets, currentUserSpecificFields }
  ) {
    const returnUrl = `${location.origin}${location.pathname}/confirmation`;
    const token = await paymentService.initP24Payment(
      eventId,
      ticketId,
      personalForm,
      discountCode,
      marketingAgreement,
      gdprConsent,
      guestsTickets,
      currentUserSpecificFields,
      returnUrl
    );
    if (token) {
      const gatewayUrl = `${PaymentConstants.GATEWAY_P24_URL}/${token}`;
      window.open(gatewayUrl, "_self");
    } else {
      throw new Error("Invalid token for P24 gateway");
    }
  },

  async [paymentGateways[PaymentConstants.GATEWAY_STRIPE_ID]](
    { dispatch },
    { eventId, ticketId, personalForm, discountCode, marketingAgreement, gdprConsent, guestsTickets, currentUserSpecificFields }
  ) {
    const clientSecret = await paymentService.initStripePayment(
      eventId,
      ticketId,
      personalForm,
      discountCode,
      marketingAgreement,
      gdprConsent,
      guestsTickets,
      currentUserSpecificFields
    );
    const stripe = await stripeService.loadStripe();
    const elements = await stripeService.loadElements();
    const cardNumber = elements.getElement("cardNumber");

    const res = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumber,
      },
    });

    if (res.error) {
      const message = res.error.message;
      throw new Error(message);
    }

    // Await for ticket arrival
    // Workaround for async stripe webhook
    // await waitForTickets(eventId);
  },

  async [paymentGateways[PaymentConstants.GATEWAY_PAYU_ID]](
    { dispatch },
    { eventId, ticketId, personalForm, discountCode, marketingAgreement, gdprConsent, guestsTickets, currentUserSpecificFields }
  ) {
    let pathname = location.pathname;
    if (pathname.endsWith("/")) {
      pathname = pathname.slice(0, -1);
    }
    const returnUrl = `${location.origin}${pathname}/confirmation`;
    const redirectUrl = await paymentService.initPayuPayment(
      eventId,
      ticketId,
      personalForm,
      discountCode,
      marketingAgreement,
      gdprConsent,
      guestsTickets,
      currentUserSpecificFields,
      returnUrl
    );
    if (redirectUrl) {
      window.open(redirectUrl, "_self");
    } else {
      throw new Error("Invalid redirectUrl for PayU gateway");
    }
  },

  async [paymentGateways[PaymentConstants.GATEWAY_BLUE_MEDIA_ID]](
    { dispatch },
    { eventId, ticketId, personalForm, discountCode, marketingAgreement, gdprConsent, guestsTickets, currentUserSpecificFields }
  ) {
    //TODO: get gatewayList and stay on the page
    const blueMediaGatewayId = 0;
    const redirectUrl = await paymentService.initBlueMediaPayment(
      eventId,
      ticketId,
      personalForm,
      discountCode,
      marketingAgreement,
      gdprConsent,
      guestsTickets,
      currentUserSpecificFields,
      blueMediaGatewayId
    );
    if (redirectUrl) {
      window.open(redirectUrl, "_self");
    } else {
      throw new Error("Invalid redirectUrl for BlueMedia gateway");
    }
  },

  async [paymentGateways[PaymentConstants.GATEWAY_FREE_TICKET_ID]](
    { dispatch },
    { eventId, ticketId, personalForm, discountCode, marketingAgreement, gdprConsent, guestsTickets, currentUserSpecificFields }
  ) {
    await paymentService.initFreePayment(
      eventId,
      ticketId,
      personalForm,
      guestsTickets,
      discountCode,
      marketingAgreement,
      gdprConsent,
      currentUserSpecificFields
    );
  },

  async checkDiscountCode({ state, rootState, commit }, discountCode) {
    const eventId = rootState.eventId;
    const ticketIds = Object.entries(state.guestsPersonalForms)
      .filter(([key, value]) => value && value.length)
      .map(([key, value]) => Number.parseInt(key));
    const body = { discount_code: discountCode, ticket_ids: ticketIds };
    return axios
      .post(`events/${eventId}/discount/check_code`, body)
      .then(res => {
        const discount = res.data;
        commit("setDiscount", discount);
        return discount;
      })
      .catch(err => {
        commit("setDiscount", null);
        throw err;
      });
  },

  async checkTransactionStatus({ rootState, commit }, { orderId, hash }) {
    const eventId = rootState.eventId;
    const body = { order_id: orderId };
    if (hash) {
      body.hash = hash;
    }
    let url;
    if (hash) {
      url = `events/${eventId}/payment/bluemedia/check`;
    } else {
      url = `events/${eventId}/payment/payu/check`;
    }
    return axios
      .post(url, body)
      .then(res => {
        let statusCode = res.data.status_code;
        commit("setTransactionStatus", statusCode);
        return statusCode;
      })
      .catch(err => {
        throw err;
      });
  },

  async checkVatNumberInVies({ rootState, commit }, vatNumber) {
    return await paymentService.checkVatNumberInVies(rootState.eventId, vatNumber);
  },
};

async function waitForTickets(eventId) {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    await wait(2500);
    const res = await axios.get(`current_user/tickets`);
    // eslint-disable-next-line prettier/prettier
    const eventTickets = res.data.event_ticket_reservations.filter(ticket => ticket.event_display_data.id === eventId);
    if (eventTickets[0]) {
      break;
    }
  }
}

function mapGuestsFormsToBodyRequest(currentUserEmail, personalForms, additionalForms) {
  return Object.entries(personalForms).reduce((arr, [key, value]) => {
    let objects = value
      .filter(form => form.email !== currentUserEmail)
      .map(form => {
        let metaData = additionalForms && additionalForms[form.email] && additionalForms[form.email].metaData;
        return {
          first_name: form.firstName,
          last_name: form.lastName,
          ticket_id: key,
          email: form.email,
          specific_fields: metaData || {},
          addons: mapAddonsToBodyRequest(form.addons),
          filter_group_responses: mapFilterGroupsToBodyRequest(form.filterGroups),
        };
      });
    return [...arr, ...objects];
  }, []);
}

function mapPersonalFormToBodyRequest(personalForm, guestPersonalForms) {
  return Object.entries(guestPersonalForms).reduce((arr, [key, value]) => {
    let objects = value
      .filter(form => form.email === personalForm.email)
      .map(form => {
        return {
          first_name: form.firstName,
          last_name: form.lastName,
          ticket_id: key,
          email: form.email,
          addons: mapAddonsToBodyRequest(form.addons),
          filter_group_responses: mapFilterGroupsToBodyRequest(form.filterGroups),
        };
      });
    return [...arr, ...objects];
  }, [])[0];
}

function mapAddonsToBodyRequest(addons) {
  return (
    (addons &&
      Object.entries(addons)
        .map(it => (it[1] ? it[0] : null))
        .filter(it => !!it)) ||
    []
  );
}

function mapFilterGroupsToBodyRequest(filterGroups) {
  return (
    (filterGroups &&
      Object.entries(filterGroups).map(it => {
        return {
          filter_group_id: Number.parseInt(it[0]),
          filter_tag_ids: it[1] || [],
        };
      })) ||
    []
  );
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
